import React, { useCallback, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

import { RouterProvider } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';

import './styles/_styles.scss';
import { useGetLocale } from 'hooks/useGetLocale';
import petdnaRouter from './routes';

const App = () => {
  useGetLocale();
  const mainContainerRef = useRef<HTMLDivElement>(null);

  const updateBg = useCallback(() => {
    if (mainContainerRef.current) {
      if (window.location.pathname.includes('recollect-confirmation')) {
        mainContainerRef.current.style.background = '#E9FEB2';
      } else {
        mainContainerRef.current.style.background = '#F1EFEA';
      }
    }
  }, []);

  const router = useMemo(() => petdnaRouter({ updateBg }), [updateBg]);

  return (
    <>
      <Toaster />
      <Helmet>
        {process.env.REACT_APP_DEV_ENV === 'production' || process.env.REACT_APP_ENV === 'stage'
          ? (
            <script>
              const unifiedTrackingInstance = unifiedTrackingFactory();
              unifiedTrackingInstance.trackScreenView(`home`);
              window.addEventListener(`unified-library-screen-view`, function (e) (console.log(e)));
            </script>
          ) : null}
      </Helmet>
      <div className="d-flex flex-column min-vh-100" ref={mainContainerRef}>
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
