export const GET_INTERIM_DASHBOARD_QUERY = `
    query PageInterimDashboard($name: String = "Interim Dashboard") {
      pageInterimDashboard(where: {name: $name}) {
        name
        pageBackgroundColor {
           hex
        }
        sectionCongratulation {
          backgroundColor {
            hex
          }
          image {
            height
            width
            url
          }
          name
          text {
            html
          }
          subText {
            html
          }
          sectionType
          navigationButtons {
            backgroundColor {
              hex
            }
            borderColor {
              hex
            }
            name
            id
            textColor {
              hex
            }
            text
            action
          }
        }
        sectionInstruction {
          name
          imageWithCaption {
            image {
              height
              width
              url
            }
            name
            subText {
              html
            }
            text {
              html
            }
            title {
              html
            }
            backgroundColor {
              hex
            }
            id
          }
          title {
            html
          }
          text {
            html
          }
          sectionType
        }
        sectionDropoff {
          backgroundColor1 {
            hex
          }
          backgroundColor2 {
            hex
          }
          inputPlaceholder1
          inputPlaceholder2
          label {
            html
          }
          name
          text {
            html
          }
          title {
            html
          }
          subTitle {
            html
          }
          subText {
            html
          }
          subLabel {
            html
          }
          sectionType
        }
        sectionSimple {
          name
          sectionType
          navigationButtons {
            backgroundColor {
              hex
            }
            borderColor {
              hex
            }
            name
            id
            textColor {
              hex
            }
            text
            action
          }
          backgroundColor1 {
            hex
          }
          backgroundColor2 {
            hex
          }
          inputPlaceholder1
          inputPlaceholder2
          subLabel {
            html
          }
          subText {
            html
          }
          subTitle {
            html
          }
          text {
            html
          }
          title {
            html
          }
        }
      }
    }
`;
