import React, {
  PropsWithChildren,
  useCallback, useMemo,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { AuthContext } from 'contexts/AuthContext';
import { useStores } from 'stores/useStores';
import { useCheckSession } from 'hooks/useCheckSession';

const AuthProvider = observer(({ children }: PropsWithChildren<any>) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { sessionStore } = useCheckSession();
  const { userStore, testsStore, amityStore } = useStores();

  const handleLogin = useCallback(async (redirectTo?: string) => {
    await sessionStore.checkSession();

    if (redirectTo) {
      window.location.href = redirectTo;
      return;
    }

    const origin = searchParams.get('returnUrl') || '/';
    navigate(decodeURIComponent(origin));
  }, [sessionStore, searchParams, navigate]);

  const handleLogout = useCallback(async () => {
    userStore.setUserProfile(null);
    testsStore.unsetProfiles();
    await amityStore.amityLogout();
    await sessionStore.signOut();
    navigate(0);
  }, [userStore, testsStore, amityStore, sessionStore, navigate]);

  const value = useMemo(() => ({
    onLogin: handleLogin,
    onLogout: handleLogout,
  }), [handleLogin, handleLogout]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
});

export default AuthProvider;
