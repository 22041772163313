import React from 'react';

import envelop from 'assets/svgs/envelop.svg';
import facebook from 'assets/svgs/facebook-icon.svg';
import sms from 'assets/svgs/message-icon.svg';
import whatsapp from 'assets/svgs/whatsapp-icon.svg';
import x from 'assets/svgs/x-icon.svg';

interface SocialButtonProps {
  icon: string;
  alt: string;
  action: () => void;
}

type SocialSharingButtonsListProps = {
  shareUrl: string
};

const SocialSharingButtonsList: React.FC<SocialSharingButtonsListProps> = ({ shareUrl }) => {
  const handleClick = (type: string) => {
    const baseText = encodeURIComponent('');
    const currentUrl = encodeURIComponent(window.location.href);

    const urlMap: { [key: string]: string } = {
      email: `mailto:example@example.com?subject=${baseText}&body=${baseText}${' '}${shareUrl}`,
      sms: `sms:+1234567890?body=${baseText}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      whatsapp: `https://api.whatsapp.com/send?text=${baseText}%20${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${baseText}&url=${shareUrl}`,
    };

    const url = urlMap[type];

    if (url) {
      window.open(url);
    }
  };

  const buttons: SocialButtonProps[] = [
    // { icon: envelop, alt: 'envelop', action: () => handleClick('email') },
    { icon: sms, alt: 'sms', action: () => handleClick('sms') },
    { icon: facebook, alt: 'facebook', action: () => handleClick('facebook') },
    { icon: whatsapp, alt: 'whatsapp', action: () => handleClick('whatsapp') },
    { icon: x, alt: 'x', action: () => handleClick('twitter') },
  ];

  return (
    <div>
      <div className="button-list-wrapper">
        <button
          type="button"
          className="btn btn-info round-6 btn-rounded-icon envelop-btn"
          onClick={() => handleClick('email')}
        >
          <img height="20" width="20" src={envelop} alt="envelop " />
        </button>
        {buttons.map((btn) => (
          <button
            key={btn.alt}
            type="button"
            className=" btn-social-list"
            onClick={btn.action}
          >
            <img height="35" width="35" src={btn.icon} alt={btn.alt} />
          </button>
        ))}
      </div>
    </div>

  );
};

export default SocialSharingButtonsList;
