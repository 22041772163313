import { useStores } from 'stores/useStores';
import { useEffect } from 'react';
import { COUNTRY_CODE_TO_LOCALE } from 'petdna-lib-uicomponents/constants';
import { useCountryCode } from 'petdna-lib-uicomponents/hooks';

export const useLoadPromoBanner = (enabled = true) => {
  const { pagesStore } = useStores();
  const countryCode = useCountryCode(false);

  useEffect(() => {
    if (!countryCode) return;
    pagesStore.getPromoBanner(enabled, [COUNTRY_CODE_TO_LOCALE[countryCode]]);
  }, [enabled, countryCode, pagesStore]);

  return { promoBanner: pagesStore?.promoBanner };
};
