import React, { useState } from 'react';
import linkIcon from 'assets/svgs/link.svg';
import toast from 'react-hot-toast';

type WebShareProps = {
  url: string;
  title?: string;
  text?: string;
};

const useWebShare = ({ url, title, text }: WebShareProps) => {
  const [status, setStatus] = useState('');

  const shareData = { title, text, url };

  const activateShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        setStatus('Content shared successfully!');
      } catch (error) {
        setStatus('Failed to share content.');
        toast.error('Failed to share content.');
      }
    } else {
      setStatus('Web Share not supported in this browser.');
      toast.error('Web Share not supported in this browser.');
    }
  };

  return { activateShare, status };
};

const WebShare: React.FC<WebShareProps> = ({ url, title = 'Ancestry PET DNA', text = 'Share your pet results.' }) => {
  const { activateShare, status } = useWebShare({ url, title, text });

  return (
    <div className="col d-flex justify-content-center align-items-center flex-column">
      <button type="button" className="btn btn-info round-6 btn-rounded-icon" onClick={activateShare}>
        <img height="20" width="20" src={linkIcon} alt="Share" />
      </button>
      <p className="ms-1">Share to</p>
    </div>
  );
};

export default WebShare;
