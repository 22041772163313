import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { DEFAULT_COUNTRY_CODE_KEY, SUPPORTED_COUNTRY_CODES, TSupportedCountryCodes } from '../constants/LCID';

export const getCountryCodeCookieFromVars = () => {
  const petDnaVars = Cookies.get('petdna_VARS') || Cookies.get('VARS');

  const countryCode = Object.fromEntries(new URLSearchParams(petDnaVars)).COUNTRYCODE;

  return countryCode as TSupportedCountryCodes;
};

const getCountryCodeFromBrowser = () => {
  // navigator.language in old Safari can return string in lowercase
  const countryCode = navigator.language.split('-')[1]?.toUpperCase() as TSupportedCountryCodes;

  if (!countryCode || !SUPPORTED_COUNTRY_CODES.includes(countryCode)) return undefined;

  return countryCode;
};

const getDefaultCountryCode = (withDefault?: boolean) => (withDefault
  ? DEFAULT_COUNTRY_CODE_KEY
  : undefined);

const getCountryCode = (withDefault: boolean = true) => getCountryCodeCookieFromVars()
                                                        || getCountryCodeFromBrowser()
                                                        || getDefaultCountryCode(withDefault);

export const useCountryCode = (withDefault: boolean = true) => {
  const [countryCode, setCountryCode] = useState<TSupportedCountryCodes>();

  useEffect(() => {
    setCountryCode(getCountryCode(withDefault));
  }, [withDefault]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCountryCode = getCountryCodeCookieFromVars();
      if (newCountryCode) {
        setCountryCode(newCountryCode);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return countryCode;
};
