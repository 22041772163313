import { getImageWithAltQuery } from '../commonQuery';

export const SECTION_WHY_ANCESTRY_QUERY = `
    SectionWhyAncestry {
      id
      __typename
      backgroundColor {
        hex
      }
      name
      sectionType
      title {
        html
      }
      text {
        html
      }
      subText
      ${getImageWithAltQuery({})}
      titleColor {
        hex
      } 
      textColor {
        hex
      }
    }`;
