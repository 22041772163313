import {
  action, configure, makeAutoObservable, observable,
} from 'mobx';
import {
  Match, MatchGroup, MatchGroups, PawfectMatchesData,
} from 'stores/types/Matches';
import apiClient from 'apiClient/index';
import { CLOSE_FAMILY_GROUP } from 'constants/matches';
import { MatchesSortBy } from 'constants/enums/matches-sort-by.enum';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class MatchesStore {
  pawfectMatchesData: PawfectMatchesData | null = null;
  dnaMatchesData: MatchGroups | null = null;
  dnaMatchesList?: Match[] | null = null;
  pawfectMatchesList: Match[] | null = null;
  loadingPawfectMatches: boolean = false;
  loadingDnaMatches: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      pawfectMatchesData: observable,
      pawfectMatchesList: observable,
      dnaMatchesData: observable,
      dnaMatchesList: observable,
      loadingPawfectMatches: observable,
      loadingDnaMatches: observable,
      getDnaMatches: action,
      getPawfectMatches: action,
      favoriteMatch: action,
      unfavoriteMatch: action,
      updateMatchStarred: action,
    });
  }

  getDnaMatches = async (
    testId: string,
    q?: string,
    sortBy?: MatchesSortBy,
    starred?: boolean,
    currentPage?: number,
  ) => {
    this.loadingDnaMatches = true;
    const matchesResponse = await apiClient.matches
      .getMatches(testId, q, sortBy, starred, currentPage);
    if (matchesResponse?.data) {
      this.dnaMatchesData = matchesResponse.data;
      if (matchesResponse.data?.matchGroups) {
        this.dnaMatchesList = matchesResponse.data.matchGroups
          .find((group: MatchGroup) => group.name.key === CLOSE_FAMILY_GROUP)?.matches;
        const newDateTime = new Date().getTime() - (20 * 24 * 60 * 60 * 1000);
        this.dnaMatchesList?.forEach((dm:Match) => {
          const matchDateTime = new Date(dm.createdDate).getTime();
          // eslint-disable-next-line no-param-reassign
          dm.new = matchDateTime > newDateTime;
        });
      }
    }
    this.loadingDnaMatches = false;
  };

  getPawfectMatches = async (
    testId: string,
    q?: string,
    starred?: boolean,
    page?: number,
    pageSize?: number,
  ) => {
    this.loadingPawfectMatches = true;
    const matchesResponse = await apiClient.matches
      .getPawfectMatches(testId, q, starred, page, pageSize);
    if (matchesResponse?.data) {
      this.pawfectMatchesData = matchesResponse.data;
      this.pawfectMatchesList = matchesResponse.data?.pawfectmatches;
    }
    this.loadingPawfectMatches = false;
  };

  favoriteMatch = async (testId: string, matchTestId: string) => {
    const matchesResponse = await apiClient.matches.addFavoriteMatch(testId, matchTestId);
    if (matchesResponse) {
      this.updateMatchStarred(matchTestId, true);
    }
  };

  unfavoriteMatch = async (testId: string, matchTestId: string) => {
    const matchesResponse = await apiClient.matches.removeFavoriteMatch(testId, matchTestId);
    if (matchesResponse) {
      this.updateMatchStarred(matchTestId, false);
    }
  };

  updateMatchStarred = (matchTestId: string, starred: boolean) => {
    if (this.dnaMatchesList) {
      const dm = this.dnaMatchesList.find((m) => m.testGUID === matchTestId);
      if (dm) {
        dm.starred = starred;
      }
    }
    if (this.pawfectMatchesList) {
      const pm = this.pawfectMatchesList.find((m) => m.testGUID === matchTestId);
      if (pm) {
        pm.starred = starred;
      }
    }
  };
}

export default MatchesStore;
