/**
 * Converts a given string to camelCase.
 *
 * @param {string} str - The input string to be converted to camelCase.
 * @returns {string} The camelCased version of the input string.
 */
export const toCamelCase = (str: string): string => str
  .replace(/^([A-Z])|[\s-_](\w)/g, (_match, p1, p2, _offset) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
