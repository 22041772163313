import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { LCID } from 'constants/LCID';

export const useGetLocale = () => {
  const [cookies] = useCookies(['VARS']);
  const { i18n } = useTranslation();

  const LCIDCookie = cookies.VARS?.split('&')?.find((cookie: string) => cookie.includes('LCID='));
  const LCIDKey: number = LCIDCookie?.replace('LCID=', '');

  useEffect(() => {
    i18n.changeLanguage(LCID[LCIDKey] ?? 'en-US');
  }, [LCIDKey, i18n]);

  return LCID[LCIDKey] || 'en-US';
};
