import { useEffect } from 'react';
import { useStores } from 'stores/useStores';

export const useGetUserPetProfiles = () => {
  const { testsStore, sessionStore } = useStores();

  useEffect(() => {
    if (!testsStore.areProfilesLoaded && !testsStore.profilesLoading && sessionStore.sessionValid) {
      testsStore.getPetProfiles();
    } else if (sessionStore.sessionChecked && !sessionStore.sessionValid) {
      testsStore.unsetProfiles();
    }
  }, [
    testsStore,
    testsStore.areProfilesLoaded,
    testsStore.profilesLoading,
    sessionStore.sessionValid,
    sessionStore.sessionChecked,
  ]);

  return { testsStore };
};
