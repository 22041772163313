import { getTransformationImageObj, ITransformation, urlWithTransformation } from 'helpers/hygraphAssetTransformation';

export const getImageQuery = (imageTransformationOptions: ITransformation, key: string = 'image') => {
  const url = urlWithTransformation(imageTransformationOptions);
  return `
    ${key} {
        width
        height
        ${url}
    }
  `;
};

export const getImageWithAltQuery = (imageTransformationOptions: ITransformation, key: string = 'imageWithAlt') => (`
      ${key} {
        id
        name
        ${getImageQuery(imageTransformationOptions)}
        altText
        localizations(includeCurrent: true) {
          locale
          altText
          ${getImageQuery(imageTransformationOptions, 'image(forceParentLocale: true)')}
        }
        locale
      }
`);

export const getMetadataQuery = (key: string = 'seo') => (`
    ${key} {
    canonicalLinkTag
    metaDataScreenName
    metaDescription
    openGraphDescription
    openGraphImage
    openGraphTitle
    openGraphType
    openGraphUrl
    title
    hiddenH1 {
      html
    }
  }
`);

export const getPartnershipsQuery = () => (`
partnerships(locales: $locale) {
  id
  name
  locale
  ${getImageWithAltQuery({}, 'partnerLogo')}
  ${getImageWithAltQuery(getTransformationImageObj(380, 256))}
  labelIcon
  labelText
  discountText
  title {
    html
  }
  text {
    html
  }
  linkText
  externalLink
  titleColor {
    hex
  }
  textColor {
    hex
  }
  labelColor {
    hex
  }
  discountBackgroundColor {
    hex
  }
  discountTextColor {
    hex
  }
}
`);

export const getImageWithCaptionQuery = (imageTransformationOptions: ITransformation, key: string = 'imageWithCaption') => (`
      ${key} {
        name
        id
        textColor {
          hex
        }
        titleColor {
          hex
        }
        ${getImageWithAltQuery(imageTransformationOptions)}
        imagePlacement
        backgroundColor {
          hex
        }
        subText {
          html
        }
        text {
          html
        }
        title {
          html
        }
        sortOrder
      }
`);
