import React from 'react';

import sadDog from '../../assets/images/sad_dog.jpg';

const NotFound = () => (
  <section className="flex-grow-1 d-flex flex-column align-items-center ">
    <div className="d-flex flex-column flex-lg-row gap-32 mt-48 justify-content-center">
      <div className="">
        <img src={sadDog} height={300} width={228} alt="Sad dog" />
      </div>
      <div className="text-center text-lg-start mt-12">
        <h1 className="title font-serif">404</h1>
        <div className="h2">
          <div>Doggone it!</div>
          <p>An error ocurred while generating the image </p>
        </div>
      </div>
    </div>
  </section>
);

export default NotFound;
