import {
  action, configure, makeAutoObservable, observable,
} from 'mobx';
import apiClient from 'apiClient/index';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class SessionStore {
  sessionValid = false;
  sessionChecked = false;
  checkingSession = false;

  constructor() {
    makeAutoObservable(this, {
      sessionValid: observable,
      sessionChecked: observable,
      checkingSession: observable,
      checkSession: action,
      signOut: action,
    });
  }

  checkSession = async () => {
    if (!this.checkingSession) {
      this.sessionChecked = false;
      this.checkingSession = true;
      apiClient.session.isSessionValid()
        .then((sessionResponse) => {
          if (sessionResponse?.data) {
            this.sessionValid = sessionResponse.data.sessionValid;
          }
        })
        .finally(() => {
          this.checkingSession = false;
          this.sessionChecked = true;
        });
    }
  };

  signOut = async () => {
    const sessionResponse = await apiClient.session.signOut();
    if (sessionResponse) {
      this.sessionValid = false;
    }
  };
}

export default SessionStore;
