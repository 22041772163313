export const HEALTH_TRAIT_RESULT_TYPE_FIELDS_QUERY = `
  traitPredictionId
    title
    titleShort
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    icon {
      url
    }
`;

export const HEALTH_TRAIT_RESULT_TYPES_QUERY = `
  query HealthTraitResultTypeQuery {
    healthTraitResultTypes {
      ${HEALTH_TRAIT_RESULT_TYPE_FIELDS_QUERY}
    }
  }
`;
