import React, {
  FC, ReactNode, useMemo, useState,
} from 'react';
import { SocialShareModalContext } from 'contexts/SocialShareModalContext';

interface SocialShareModalProviderProps {
  children: ReactNode;
}

export const SocialShareModalProvider: FC<SocialShareModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const contextValue = useMemo(() => ({ isOpen, openModal, closeModal }), [isOpen]);

  return (
    <SocialShareModalContext.Provider value={contextValue}>
      {children}
    </SocialShareModalContext.Provider>
  );
};
