import { getPartnershipsQuery } from '../commonQuery';

export const SECTION_PARTNERSHIPS_QUERY = `
  sectionPartnerships {
    title {
      html
    }
    backgroundColor {
      hex
    }
    titleColor {
      hex
    }
    ${getPartnershipsQuery()}
  }
`;
