/* istanbul ignore file */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_common from './en/common.json';
import es_common from './es/common.json';
import de_common from './de/common.json';
import fr_common from './fr/common.json';
import sv_common from './sv/common.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const resources = {
  en: {
    common: en_common,
  },
  es: {
    common: es_common,
  },
  de: {
    common: de_common,
  },
  fr: {
    common: fr_common,
  },
  sv: {
    common: sv_common,
  },
} as const;

i18n
  .use(initReactI18next)
  .init({
    returnNull: true,
    fallbackLng: 'en',
    ns: ['common'],
    interpolation: {
      escapeValue: false,
    },
    cleanCode: false,
    load: 'all',
    nonExplicitSupportedLngs: true,
    resources,
  });

export default i18n;
