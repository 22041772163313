import { getMetadataQuery } from './commonQuery';
import { SECTION_PARTNERSHIPS_QUERY } from './sections/sectionPartnershipsQuery';
import {
  getSectionTextAndImageQuery,
} from './sections/sectionTextAndImageQuery';

export const PAGE_PARTNERSHIPS_QUERY = `
query PagePartnerships($name: String! = "Partnerships", $locale: [Locale!]!) {
  pagePartnership(where: {name: $name}) {
    ${getMetadataQuery()}
    sectionTextAndImage {
      ... on ${getSectionTextAndImageQuery(450, 450)}
    }
    ${SECTION_PARTNERSHIPS_QUERY}
  }
}
`;
