import { HYGRAPH_PAGE_ACCOUNT_ID } from 'constants/hygraphShemeId';

export const GET_COOKIES_DISCLOSURE = `
  query PageAccount($id: ID = "${HYGRAPH_PAGE_ACCOUNT_ID}") {
    pageAccount(where: {id: $id}) {
      consentItem {
        name
        title
        content {
          markdown
        }
      }
    }
  }
`;
