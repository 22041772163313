export const SECTION_TEXT_AND_TITLE_QUERY = `SectionTextAndTitle {
  id
  __typename
  title {
    html
  }
  text {
    html
  }
  titleColor {
    hex
  }
  textColor {
    hex
  }
  backgroundColor {
    hex
  }
}`;
