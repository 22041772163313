import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface IFeatureRoute {
  route: string
  featureFlag: string
}

const featureRoutes: IFeatureRoute[] = [
  {
    route: '/co-branded',
    featureFlag: 'coBrandedPageEnabled',
  },
];

export const useIsFeaturedPage = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const featureMarkedRoute = featureRoutes.find(
      ({ route }) => location.pathname.endsWith(route),
    );

    if (!featureMarkedRoute) {
      return;
    }
    const showPage = searchParams.get(featureMarkedRoute.featureFlag) === 'true';
    if (!showPage) navigate('/');
  }, [location, navigate, searchParams]);
};
