import { createContext } from 'react';

type TAuthValue = {
  onLogin: (redirectTo?: string) => void,
  onLogout: () => void,
};
export const AuthContext = createContext<TAuthValue>({
  onLogin: () => {},
  onLogout: () => {},
});
