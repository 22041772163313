export const getShareSheetTextQuery = (id: string) => (`query shareSheetText($id: ID = "${id}") {
  values: shareSheetText(where: {id: $id}) {
    id
    id
    name
    shareType
    text {
      text
    }
    title
  }
}
`);

// Hygraph IDs for Share Sheet Text content
export const MIXED_BREED_SHARE_SHEET_TEXT_ID = 'clo7aeoyh0pf90bl9166opf5w';

export const PURE_BREED_SHARE_SHEET_TEXT_ID = 'clo7amptp0pxx0bl9b8ek349w';
