export const KIT_PRICES_QUERY = `
   kitPrices {
    id
    priceSale
    priceFull
    saleCountry
    terms
    queryParams
    lcid
   }
`;

export const KIT_PRICES_ONLY_QUERY = `
query KitPrices {
    ${KIT_PRICES_QUERY}
}
`;

export const KIT_PRICE_FRAGMENT = `... on KitPrice {
    id
    priceSale
    priceFull
    saleCountry
    terms
    queryParams
    lcid
}`;
