import { createContext } from 'react';

interface SocialShareModalContextProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const SocialShareModalContext = createContext<SocialShareModalContextProps
| undefined>(undefined);
