import { HYGRAPH_PAGE_LI_DASHBOARD_ID } from 'constants/hygraphShemeId';

export const GET_LOGGED_IN_DASHBOARD_QUERY = `query PageLIDashboard($id: ID = "${HYGRAPH_PAGE_LI_DASHBOARD_ID}"){
  pageLiDashboard(where: {id: $id}) {
    card {
      name
      backgroundImage {
        url
      }
      buttonBorderColor {
        hex
      }
      buttonColor {
        hex
      }
      buttonExternalLink
      buttonText
      buttonTextColor {
        hex
      }
      contentDate
      title {
        text
      }
      text {
        text
      }
    }
    name
  }
}
`;
