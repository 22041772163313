import { getTransformationImageObj } from 'helpers/hygraphAssetTransformation';
import { getImageWithAltQuery } from '../commonQuery';
import { GET_QUESTIONS_QUERY } from '../questionsQuery';

export const SECTION_SUBMIT_FOR_CO_BRAND = `
  SectionSubmitForCoBrand {
    id
    __typename
    ${getImageWithAltQuery(getTransformationImageObj(600, 406, 'clip'))}
    ${getImageWithAltQuery({}, 'logo')}
    title {
      html
    }
    text {
      html
    }
    questionTitle {
      html
    }
    questionSubtitle {
      html
    }
    ${GET_QUESTIONS_QUERY}
    buttonText
    signInText {
      html
    }
    backgroundColor {
      hex
    }
    cardBackgroundColor {
      hex
    }
    titleColor {
      hex
    }
    textColor {
      hex
    }
  }
`;
