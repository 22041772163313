import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { FooterMenuItem } from 'stores/types/Pages';
import { Profile } from 'stores/types/Profiles';
import { useAuth } from 'hooks/useAuth';
import DisplayNameModal from 'components/Modals/DisplayNameModal/DisplayNameModal';
import { useGetUserProfile } from 'hooks/useGetUserProfile';
import { useGetPetProfile } from 'hooks/useGetPetProfile';

declare const bootstrap: any;

type TProps = {
  links?: FooterMenuItem[];
  petProfiles: Profile[] | null;
  unreadCount: number;
};

const MobileMenu = ({ links, petProfiles, unreadCount }: TProps) => {
  const { t } = useTranslation();
  const { onLogout } = useAuth();
  const [offcanvas, setOffcanvas] = useState<any>();
  const offcanvasRef = useRef(null);
  const { testsStore: { petProfile } } = useGetPetProfile();
  const params = useParams();
  const testId = params.testId ?? petProfile?.testGUID;
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const { userStore: { userProfile } } = useGetUserProfile();

  useEffect(() => {
    if (offcanvasRef.current) {
      setOffcanvas(new bootstrap.Offcanvas(offcanvasRef.current));
    }
  }, [offcanvasRef]);

  const linkClick = () => {
    if (offcanvas) {
      offcanvas.hide();
    }
  };
  const onCloseNameModal = () => {
    setIsNameModalOpen(false);
  };

  const handleChatClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    linkClick();
    if (userProfile?.PetDNADisplayName === null || userProfile?.PetDNADisplayName === undefined) {
      e.preventDefault();
      setIsNameModalOpen(true);
    }
  };

  const logOut = () => {
    if (offcanvas) {
      offcanvas.hide();
    }
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <aside
      className="offcanvas offcanvas-end"
      id="mobileHeaderMenu"
      ref={offcanvasRef}
      tabIndex={-1}
    >
      <div className="offcanvas-header align-self-end">
        <button
          type="button"
          className="btn btn-link-light btn-icon-only btn-menu-close icon-16"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <span className="icon icon-close" />
        </button>
      </div>
      <div className="offcanvas-body">
        <nav aria-label="Mobile menu">
          {!petProfiles?.length ? (
            <>
              <ul className="nav flex-column">
                {links?.map((link) => (
                  <li className="nav-item" key={link.name}>
                    <a
                      className={`nav-link link link-primary font-serif fs-2 ${link.cssClass}`}
                      href={link.externalLink || `/${link.link}`}
                      onClick={linkClick}
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
              <hr />
            </>
          ) : null}
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/"
                end
                onClick={linkClick}
              >
                {t('my.pets')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/profile"
                end
                onClick={linkClick}
              >
                {t('account.info.header')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/register"
                end
                onClick={linkClick}
              >
                {t('navbar.registerKit')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/all/chat"
                end
                onClick={(e) => { handleChatClick(e); }}
              >
                {t('navbar.messages')}
              </NavLink>
              {unreadCount
                ? (
                  <span className="badge mobile-unread-count-badge">{unreadCount < 100 ? unreadCount : '99+'}</span>
                ) : null}
            </li>
            <li>
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/partnerships"
                end
                onClick={linkClick}
              >
                {t('navbar.partnership')}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className="offcanvas-footer">
        <button
          className="btn btn-secondary w-100"
          onClick={logOut}
          type="button"
        >
          {t('navbar.signOut')}
        </button>
      </div>
      {
        testId
        && (
          <DisplayNameModal
            isNameModalOpen={isNameModalOpen}
            id={testId}
            onClose={() => onCloseNameModal()}
          />
        )
      }
    </aside>
  );
};

export default MobileMenu;
