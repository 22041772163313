export const SECTION_BULLET_LIST_QUERY = `SectionBulletList {
    title {
      html
    }
    items {
      html
    }
    textColor {
      hex
    }
    backgroundColor {
      hex
    }
    borderColor {
      hex
    }
    borderWidth
    titleFontSize
  }`;
