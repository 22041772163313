import {
  action, configure, makeAutoObservable, observable,
} from 'mobx';
import apiClient from 'apiClient/index';
import {
  HealthTraitInfo,
  TraitBasicInfo, TraitCategory, TraitInfo, TraitsResults,
} from 'stores/types/Traits';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class TraitsStore {
  categories = ['BEHAVIORAL', 'PHYSICAL'];
  traitsBasicInfo = new Map<string, TraitBasicInfo>();
  allTraitsBasicInfo:TraitBasicInfo[] = [];
  traitInfo = new Map<string, TraitInfo>();
  traitCategories: { [category: string]: any[] } = {
    BEHAVIORAL: [],
    PHYSICAL: [],
  };

  traitsResults: TraitsResults | null = null;
  traitsResultsLoaded = false;
  loadingTraits = false;

  constructor() {
    makeAutoObservable(this, {
      traitsBasicInfo: observable,
      allTraitsBasicInfo: observable,
      traitInfo: observable,
      traitCategories: observable,
      traitsResults: observable,
      traitsResultsLoaded: observable,
      loadingTraits: observable,
      getTraitsResults: action,
      getTraitInfo: action,
      getTraitBasicInfo: action,
      setTraitCategories: action,
      setTraitCategoriesNew: action,
      addTraitInfo: action,
    });
  }

  getTraitsResults = async (testId: string) => {
    this.loadingTraits = true;
    const traitsResponse = await apiClient.traits.getTraitsResults(testId);
    if (traitsResponse?.data) {
      const traits = (traitsResponse.data.traits || [])
        .filter((trait) => !!trait.prediction)
        .filter((trait, i, all) => all.findIndex((t) => t.id === trait.id) === i);
      this.traitsResults = { traits };
      await this.getAllTraitBasicInfo();
      this.setTraitCategoriesNew();
    }
    this.traitsResultsLoaded = true;
    this.loadingTraits = false;
  };

  getTraitInfo = async (traitId: string) => {
    if (this.traitInfo.has(traitId)) {
      return this.traitInfo.get(traitId);
    }
    const traitResponse = await apiClient.traits.getTraitInfo(traitId);
    if (traitResponse?.data
        && traitResponse.data.data
        && traitResponse.data.data.traits
        && traitResponse.data.data.traits.length > 0) {
      const data = traitResponse.data.data.traits[0];
      data.references = data.references
        .filter((val, i) => i === data.references.findIndex((r) => r.html === val.html));
      this.traitInfo.set(traitId, data);
      return data;
    }
    return null;
  };

  getTraitBasicInfo = async (traitIds: string[]) => {
    const newTraitIds = (traitIds || []).filter((id) => !this.traitsBasicInfo.has(id));
    if (newTraitIds.length > 0) {
      const traitsResponse = await apiClient.traits.getTraitList(newTraitIds);
      if (traitsResponse?.data && traitsResponse.data.data) {
        this.addTraitInfo(traitsResponse.data.data.traits);
      }
    }
  };

  getAllTraitBasicInfo = async () => {
    const traitsResponse = await apiClient.traits.getAllTraitList();
    if (traitsResponse?.data && traitsResponse.data.data) {
      this.allTraitsBasicInfo = traitsResponse.data.data.traits;
    }
  };

  addTraitInfo(traitsInfo: TraitBasicInfo[]) {
    const tempInfo = this.traitsBasicInfo;
    traitsInfo.forEach((info) => {
      if (!tempInfo.has(info.traitId)) {
        tempInfo.set(info.traitId, info);
      }
    });
    this.traitsBasicInfo = tempInfo;
  }

  setTraitCategories = () => {
    const traitCats: { [category: string]: any[] } = {
      BEHAVIORAL: [],
      PHYSICAL: [],
      UNDETERMINED: [],
    };
    if (this.traitsResults && this.traitsResults.traits) {
      this.traitsResults.traits.forEach((trait) => {
        const basicInfo = this.traitsBasicInfo.get(trait.id);
        if (basicInfo) {
          traitCats[basicInfo.traitType].push({
            traitId: trait.id,
            name: basicInfo.name,
            imgUrl: basicInfo.image?.url,
            result: basicInfo.traitResults
              .find((r) => r.predictionId === trait.prediction)?.title || '',
          });
        }
      });
    }
    this.traitCategories = traitCats;
  };

  setTraitCategoriesNew = () => {
    const traitCats: Record<TraitCategory, any[]> = {
      BEHAVIORAL: [],
      PHYSICAL: [],
      UNDETERMINED: [],
    };

    const traitsResults = this.traitsResults?.traits || [];
    const allTraitsBasicInfo = this.allTraitsBasicInfo || [];

    /**
     * Sorting the data for sending the NA predictions to the bottom of the array
     * if the BE response is not formatted
     */
    traitsResults.sort((a, b) => {
      if (a.prediction === 'NA' && b.prediction !== 'NA') {
        return 1;
      } if (a.prediction !== 'NA' && b.prediction === 'NA') {
        return -1;
      }
      return 0;
    });

    allTraitsBasicInfo.forEach((traitInfo) => {
      const matchedResult = traitsResults.find((result) => result.id === traitInfo.traitId);
      if (matchedResult) {
        const extendedResult = {
          ...matchedResult,
          traitId: traitInfo.traitId,
          name: traitInfo.name,
          imgUrl: traitInfo.image?.url,
          result: traitInfo.traitResults
            .find((r) => r.predictionId === matchedResult.prediction)?.title || '',
        };

        const category = traitInfo.traitType ?? 'UNDETERMINED';
        if (category in traitCats) {
          traitCats[category as TraitCategory].push(extendedResult);
        }
      } else {
        traitCats.UNDETERMINED.push({
          id: traitInfo.traitId,
          traitId: traitInfo.traitId,
          name: traitInfo.name,
          imgUrl: traitInfo.image?.url,
          result: 'Undetermined',
        });
      }
    });

    Object.keys(traitCats).forEach((category) => {
      traitCats[category as TraitCategory].sort((a, b) => a.name.localeCompare(b.name));
    });

    this.traitCategories = traitCats;
  };
}

export default TraitsStore;
