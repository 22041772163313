import { DEFAULT_COUNTRY_CODE, useGetCountryCode } from 'hooks/useGetCountryCode';
import { useMemo } from 'react';
import { useGetKitPrices } from 'hooks/useGetKitPrices';
import { IKitPrice } from 'stores/types/Hygraph';
import { useSearchParams } from 'react-router-dom';
import { getDomain } from 'helpers/getDomain';

export const useGetCheckoutUrl = (forceKitPriceValue: IKitPrice | null = null) => {
  const { pagesStore } = useGetKitPrices();
  const { kitPrices, areKitPricesLoaded } = pagesStore;
  const countryCode = useGetCountryCode();
  const domain = getDomain();
  const [searchParams] = useSearchParams();
  const cid = searchParams.get('cid') || '';

  return useMemo(() => {
    let params: string | undefined;
    if (areKitPricesLoaded && kitPrices.length > 0 && !forceKitPriceValue) {
      const kitPrice = kitPrices.find((kp) => kp.saleCountry === countryCode);
      const defaultKitPrice = kitPrices.find((kp) => kp.saleCountry === DEFAULT_COUNTRY_CODE);

      params = kitPrice ? kitPrice.queryParams : defaultKitPrice?.queryParams;
    }

    if (forceKitPriceValue) {
      params = forceKitPriceValue.queryParams;
    }

    if (cid) {
      params = `${params || ''}&coupon=${cid}`;
    }

    return `https://petdna.${domain}/checkout/mli?${params}`;
  }, [areKitPricesLoaded, kitPrices, forceKitPriceValue, cid, domain, countryCode]);
};
