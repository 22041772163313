import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

declare const bootstrap: any;

const ProfileMenu = () => {
  const { t } = useTranslation();
  const { onLogout } = useAuth();
  const [offcanvas, setOffcanvas] = useState<any>();
  const offcanvasRef = useRef(null);

  useEffect(() => {
    if (offcanvasRef.current) {
      setOffcanvas(bootstrap.Offcanvas.getOrCreateInstance(offcanvasRef.current));
    }
  }, [offcanvasRef]);

  const linkClick = () => {
    if (offcanvas) {
      offcanvas.hide();
    }
  };

  const logOut = () => {
    if (offcanvas) {
      offcanvas.hide();
    }
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <aside
      className="offcanvas offcanvas-end bg-dark"
      id="profileMenu"
      ref={offcanvasRef}
      tabIndex={-1}
    >
      <div className="offcanvas-header">
        <div className="offcanvas-title"><i className="icon icon-person bg-light" /></div>
        <button
          className="btn btn-link-light btn-icon-only btn-sm"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          type="button"
        >
          <i className="icon icon-close icon-16" />
        </button>
      </div>
      <div className="offcanvas-body">
        <nav aria-label="Profile menu">
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/"
                end
                onClick={linkClick}
              >
                {t('my.pets')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/profile"
                end
                onClick={linkClick}
              >
                {t('account.info.header')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link link link-primary font-serif fs-2"
                to="/register"
                end
                onClick={linkClick}
              >
                {t('navbar.registerKit')}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className="offcanvas-footer">
        <button
          className="btn btn-secondary w-100"
          onClick={logOut}
          type="button"
        >
          {t('navbar.signOut')}
        </button>
      </div>
    </aside>
  );
};

export default ProfileMenu;
