import { getImageQuery } from 'constants/query/imageQuery';
import { KIT_PRICES_QUERY } from 'constants/query/kitPricesQuery';

export const GET_OFFER_QUERY = ` 
    query PageLiOffer($cid: String!) {
      pageLiOffer(where: {couponPrefix: $cid}) {
        name
        sectionBuyNow {
          ${getImageQuery(716, 571)}
          ${KIT_PRICES_QUERY}
          buttonAction
          buttonText
          imagePlacement
          name
          sectionType
          text {
            html
          }
          title {
            html
          }
          backgroundColor {
            hex
          }
        }
      }
    }
`;
