import { getImageQuery } from 'constants/query/commonQuery';

export const HEALTH_TRAIT_BODY_FUNCTION_FIELDS = `
  id
  title
  ${getImageQuery({}, 'icon')}
`;

export const HEALTH_TRAIT_BODY_FUNCTIONS_QUERY = `
  query HealthTraitBodyFunctionsQuery {
    healthTraitBodyFunctions {
      ${HEALTH_TRAIT_BODY_FUNCTION_FIELDS}
    }
  }
`;
