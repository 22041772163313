import { useStores } from 'stores/useStores';
import { useEffect } from 'react';

export const useLoadNavbar = () => {
  const { pagesStore } = useStores();

  useEffect(() => {
    const timer = setTimeout(() => {
      pagesStore.getNavbar();
    }, 3000);

    return () => clearTimeout(timer);
  }, [pagesStore]);

  return { navbarData: pagesStore?.navbarData };
};
