import { getImageQuery } from 'constants/query/commonQuery';

export const SECTION_DASHBOARD_PARTNERSHIP_QUERY = `query SectionDashboardPartnership {
    sectionDashboardPartnerships(first: 1) {
      sectionTitle
      title {
        html
      }
      description {
        html
      }
      linkText
      ${getImageQuery({}, 'image')}
    }
  }`;
