import { useEffect } from 'react';
import { useStores } from 'stores/useStores';
import { Client } from '@amityco/ts-sdk';
import { useGetUserProfile } from './useGetUserProfile';

export const useCreateAmityClient = (checkIfHasChat = true) => {
  const { userStore: { userProfile } } = useGetUserProfile();
  const { amityStore, sessionStore: { sessionValid }, testsStore: { petProfiles } } = useStores();

  useEffect(() => {
    const createClient = async () => {
      let canCreateAmity = true;

      if (checkIfHasChat) {
        canCreateAmity = await amityStore.getHasAnyChannel();
      }

      if (canCreateAmity && userProfile?.UserId !== undefined && !amityStore.client) {
        await amityStore.createAmityClient(userProfile?.UserId, userProfile?.PetDNADisplayName);
      }
    };

    if (sessionValid && petProfiles?.length) createClient();

    return () => {
      if (sessionValid) Client.stopUnreadSync();
    };
  }, [
    amityStore,
    petProfiles?.length,
    sessionValid,
    userProfile?.PetDNADisplayName,
    userProfile?.UserId,
    checkIfHasChat,
  ]);

  return amityStore;
};
