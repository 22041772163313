import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet-async';

const Sourcepoint = () => (
  <Helmet>
    {
        process.env.REACT_APP_DEV_ENV === 'production' ? (
          <script type="text/javascript">
            {`
            window._sp_queue = [];
            window._sp_ = {
              config: {
                accountId: 1726,
                baseEndpoint: 'https://cdn.privacy-mgmt.com',
                
                ccpa: {
                  includeGppApi: true
                },
                custom: { },
                gdpr: { },
                events: {
       
                      onMessageChoiceSelect: function() {
                          console.log('[event] onMessageChoiceSelect', arguments);
                      },
                      onMessageReady: function() {
                          console.log('[event] onMessageReady', arguments);
                      },
                      onMessageChoiceError: function() {
                          console.log('[event] onMessageChoiceError', arguments);
                      },
                      onPrivacyManagerAction: function() {
                          console.log('[event] onPrivacyManagerAction', arguments);
                      },
                      onPMCancel: function() {
                          console.log('[event] onPMCancel', arguments);
                      },
                      onMessageReceiveData: function() {
                          console.log('[event] onMessageReceiveData', arguments);
                      },
                      onSPPMObjectReady: function() {
                          console.log('[event] onSPPMObjectReady', arguments);
                      },
                     onConsentReady: function(message_type, uuid, string, info) {
                     
                                   
                        __tcfapi('addEventListener', 2, (_gdpr) => {
        console.log(_gdpr);
        if (_gdpr.gdprApplies) {
            //console.log("GDPR APPLIES");
            __tcfapi('getCustomVendorConsents', 2, (noniab) => {
                console.log(noniab.grants);
                //Do your GDPR Stuff Here
                let eve = new CustomEvent("sp_Consent", {
                    'type': 'gdpr',
                    'detail': noniab
                });
                window.dispatchEvent(eve);

            })
        } else {
            __uspapi('getUSPData', 1, (usp) => {
                __uspapi('getCustomVendorRejects', 1, (usp_cust) => {
                    console.log(usp_cust);
                    //console.log(usp_cust);
                    let lev = new CustomEvent("sp_Consent", {
                        'type': 'ccpa',
                        'detail': usp_cust
                    });
                    window.dispatchEvent(lev);
                });
            });
        }
    });   
                },
                onError: function() {
                    console.log('[event] onError', arguments);
                },
                }
              }
            };
          `}
          </script>
        ) : (
          <script type="text/javascript">
            {`
            window._sp_queue = [];
            window._sp_ = {
              config: {
                accountId: 1726,
                baseEndpoint: 'https://cdn.privacy-mgmt.com',
                propertyHref: 'https://petdna.ancestry.com',
                joinHref: true,
                ccpa: {
                  includeGppApi: true
                },
                custom: { },
                gdpr: { },
                events: { 
       
                      onMessageChoiceSelect: function() {
                          console.log('[event] onMessageChoiceSelect', arguments);
                      },
                      onMessageReady: function() {
                          console.log('[event] onMessageReady', arguments);
                      },
                      onMessageChoiceError: function() {
                          console.log('[event] onMessageChoiceError', arguments);
                      },
                      onPrivacyManagerAction: function() {
                          console.log('[event] onPrivacyManagerAction', arguments);
                      },
                      onPMCancel: function() {
                          console.log('[event] onPMCancel', arguments);
                      },
                      onMessageReceiveData: function() {
                          console.log('[event] onMessageReceiveData', arguments);
                      },
                      onSPPMObjectReady: function() {
                          console.log('[event] onSPPMObjectReady', arguments);
                      },
                     onConsentReady: function(message_type, uuid, string, info) {
                     
                                   
                        __tcfapi('addEventListener', 2, (_gdpr) => {
        console.log(_gdpr);
        if (_gdpr.gdprApplies) {
            //console.log("GDPR APPLIES");
            __tcfapi('getCustomVendorConsents', 2, (noniab) => {
                console.log(noniab.grants);
                //Do your GDPR Stuff Here
                let eve = new CustomEvent("sp_Consent", {
                    'type': 'gdpr',
                    'detail': noniab
                });
                window.dispatchEvent(eve);

            })
        } else {
            __uspapi('getUSPData', 1, (usp) => {
                __uspapi('getCustomVendorRejects', 1, (usp_cust) => {
                    console.log(usp_cust);
                    //console.log(usp_cust);
                    let lev = new CustomEvent("sp_Consent", {
                        'type': 'ccpa',
                        'detail': usp_cust
                    });
                    window.dispatchEvent(lev);
                });
            });
        }
    });   
                },
                onError: function() {
                    console.log('[event] onError', arguments);
                },
                }
              }
            };
          `}
          </script>
        )
      }
    <script src="https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js" async />
    <script>
      {
        `    
    __tcfapi('addEventListener', 2, (_gdpr) => {
        console.log(_gdpr);
        if (_gdpr.gdprApplies) {
            //console.log("GDPR APPLIES");
            __tcfapi('getCustomVendorConsents', 2, (noniab) => {
                console.log(noniab.grants);
                //Do your GDPR Stuff Here
                /* code to insert the GDPR footer link */
                let eve = new CustomEvent("sp_Consent", {
                    'type': 'gdpr',
                    'detail': noniab
                });
                window.dispatchEvent(eve);

            })
        } else {
            __uspapi('getUSPData', 1, (usp) => {
                __uspapi('getCustomVendorRejects', 1, (usp_cust) => {
                    console.log(usp_cust);
                    //Do your US Privacy Stuff Here
                    let lev = new CustomEvent("sp_Consent", {
                        'type': 'ccpa',
                        'detail': usp_cust
                    });
                    window.dispatchEvent(lev);
                });
            });
        }
    });
        `
      }
    </script>
  </Helmet>
);

export default Sourcepoint;
