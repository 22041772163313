/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState, useRef } from 'react';
import { useLoadFooter } from 'hooks/useLoadFooter';
import { FooterMenuItem } from 'stores/types/Pages';
import { observer } from 'mobx-react-lite';
import { toCamelCase } from 'helpers/common';

interface MyWindow extends Window {
  _sp_?: {
    ccpa?: {
      loadPrivacyManagerModal: (id: string) => void;
    };
    gdpr?: {
      loadPrivacyManagerModal: (id: string) => void;
    };
  };
}

const SiteFooter: React.FC = observer(() => {
  const { footerData } = useLoadFooter();
  const myWindow = window as MyWindow;
  const [showCCPAButtton, setShowCCPAButtton] = useState(false);
  const [showGDPRButton, setShowGDPRButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const localStorageItem = localStorage.getItem('_sp_user_consent_31379');
      if (localStorageItem) {
        try {
          const parsedData = JSON.parse(localStorageItem);

          if (parsedData.ccpa.applies) {
            setShowCCPAButtton(true);
          } else if (parsedData.gdpr.applies) {
            setShowGDPRButton(true);
          }
        } catch (e) {
          console.error('Error parsing LocalStorage', e);
        }
      } else {
        console.warn('Ítem not Found');
      }
    }, 1250);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleGDPR = () => {
    myWindow._sp_!.gdpr!.loadPrivacyManagerModal('812894');
  };

  const handleCCPA = () => {
    myWindow._sp_!.ccpa!.loadPrivacyManagerModal('812895');
  };

  const renderPrivacyButton = () => {
    if (showCCPAButtton) {
      return (
        <button
          id="pmLink"
          type="button"
          onClick={handleCCPA}
          className="link link-light-primary text-decoration-none"
          aria-label="Do Not Sell or Share My Personal Information"
        >
          Do Not Sell or Share My Personal Information
        </button>
      );
    }
    if (showGDPRButton) {
      return (
        <button
          id="pmLink"
          type="button"
          onClick={handleGDPR}
          className="link link-light-primary text-decoration-none"
          aria-label="Manage GDPR Privacy Settings"
        >
          GDPR Privacy Settings
        </button>
      );
    }
    return <> </>;
  };

  return (
    <footer
      className="bg-dark p-16 pb-40 p-lg-40 d-flex flex-column flex-lg-row gap-16 justify-content-between"
      role="contentinfo"
    >
      <div className="d-flex flex-column flex-lg-row gap-8 gap-lg-24">
        {footerData?.leftMenuItems?.map((footerItem: FooterMenuItem) => (
          <a
            key={footerItem.name}
            className={`link link-light-primary text-decoration-none ${footerItem?.cssClass ? footerItem.cssClass : ''}`}
            href={footerItem?.link ? `/${footerItem.link}` : footerItem?.externalLink}
            role="link"
            aria-label={footerItem.label}
          >
            {footerItem.label}
          </a>
        ))}
      </div>
      <hr className="d-lg-none" aria-hidden="true" />
      <div className="d-flex flex-column flex-lg-row gap-8 gap-lg-24">
        {
          renderPrivacyButton()
        }
        {footerData?.rightMenuItems?.map((footerItem: FooterMenuItem) => {
          const idCamelCase = toCamelCase(footerItem.name);
          return (
            idCamelCase === 'ccpaNoticeAtCollection' && showGDPRButton
              ? <React.Fragment key={idCamelCase} />
              : (
                <a
                  key={footerItem.name}
                  id={idCamelCase}
                  className={`link link-light-primary text-decoration-none ${footerItem?.cssClass ? footerItem.cssClass : ''}`}
                  href={footerItem?.link ? `/${footerItem.link}` : footerItem?.externalLink}
                  role="link"
                  aria-label={footerItem.label}
                >
                  {footerItem.label}
                </a>
              )
          );
        })}
      </div>
    </footer>
  );
});

export default SiteFooter;
