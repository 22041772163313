import { getImageWithAltQuery } from 'constants/query/commonQuery';
import { getTransformationImageObj } from 'helpers/hygraphAssetTransformation';

export const getSectionTraitDescriptionQuery = (
  imageWidth = 500,
  imageHeight = 500,
) => `SectionTraitDescription {
    title {
      html
    }
    description {
      html
    }
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    ${getImageWithAltQuery(getTransformationImageObj(imageWidth, imageHeight))}
  }`;
