import {
  action,
  makeAutoObservable,
  configure,
  observable,
} from 'mobx';
import { TestHistory, TTestInfo, ShareSheetTextItem } from 'stores/types/Tests';
import {
  Profile,
  AddressInfo,
  RecollectResults,
  AddressResponse,
} from 'stores/types/Profiles';
import { BreedResult } from 'stores/types/Breeds';
import apiClient from '../apiClient';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class TestsStore {
  downloadUrl: string = '';

  subject: any | null = null;
  subjectsViewInfo: { [key: string]: any } = {};
  isSubjectLoaded = false;

  petProfile: Profile | null = null;
  isProfileLoaded = false;
  profileLoading = false;

  petProfiles: Profile[] | null = [];
  areProfilesLoaded = false;
  profilesLoading = false;

  testHistory: TestHistory | null = null;
  testHistoryLoading = false;

  tests: TTestInfo[] = [];
  testsLoaded = false;
  testsLoading = false;

  userAddresses: AddressResponse | null = null;
  isUserAddressesLoaded = false;
  userAddressesLoading = false;

  recollectAcceptResults: RecollectResults | null = null;
  recollectAcceptLoaded = false;
  recollectAcceptLoading = false;

  shareSheetText: ShareSheetTextItem | null = null;
  shareSheetTextLoaded = false;
  shareSheetTextLoading = false;

  generatedImage: any | null = null;
  generatedImageLoaded: boolean = false;
  generatedImageLoading: boolean = false;

  receiveMessages: string = 'null';

  constructor() {
    makeAutoObservable(this, {
      downloadUrl: observable,
      subject: observable,
      subjectsViewInfo: observable,
      isSubjectLoaded: observable,
      testHistory: observable,
      tests: observable,
      testsLoaded: observable,
      testsLoading: observable,
      petProfile: observable,
      petProfiles: observable,
      isProfileLoaded: observable,
      profileLoading: observable,
      testHistoryLoading: observable,
      areProfilesLoaded: observable,
      profilesLoading: observable,
      userAddresses: observable,
      isUserAddressesLoaded: observable,
      userAddressesLoading: observable,
      generatedImage: observable,
      generatedImageLoaded: observable,
      generatedImageLoading: observable,
      receiveMessages: observable,
      downloadInfo: action,
      getTestHistory: action,
      getTests: action,
      getPetProfile: action,
      getPetProfiles: action,
      updatePetProfiles: action,
      deletePetProfile: action,
      setProfile: action,
      setProfileLoaded: action,
      unsetProfiles: action,
      updateMessagesReceiveStatus: action,
    });
  }

  downloadInfo = async (testId: string) => {
    const downloadResponse = await apiClient.tests.downloadInfo(testId);
    if (downloadResponse?.data) {
      this.downloadUrl = downloadResponse.data?.message?.url;
      return downloadResponse.data?.message?.url;
    }
    return null;
  };

  getTestHistory = async (testId: string) => {
    this.testHistoryLoading = true;
    const testHistoryResponse = await apiClient.tests.getTestHistory(testId);
    if (testHistoryResponse?.data) {
      this.testHistory = testHistoryResponse.data;
    }
    this.testHistoryLoading = false;
  };

  getTests = async () => {
    this.testsLoading = true;
    apiClient.tests.getTests()
      .then((testsResponse) => {
        if (testsResponse?.data) {
          this.tests = testsResponse.data
            .map((test) => {
              if (test.subjectBirthDate) {
                // eslint-disable-next-line no-param-reassign
                test.subjectBirthDate = new Date(test.subjectBirthDate);
              }
              return test;
            })
            .sort((a, b) => {
              const aName = `${a.subjectFirstName} ${a.subjectLastName}`.trim();
              const bName = `${b.subjectFirstName} ${b.subjectLastName}`.trim();
              return aName.localeCompare(bName);
            });
        }
      })
      .finally(() => {
        this.testsLoading = false;
        this.testsLoaded = true;
      });
  };

  getPetProfile = async (testId: string) => {
    this.profileLoading = true;
    this.setProfileLoaded(false);
    const profileResponse = await apiClient.tests.getPetProfile(testId);
    if (profileResponse?.data) {
      this.setProfile(profileResponse.data.profiles);
    }
    this.profileLoading = false;
  };

  getPetProfiles = async () => {
    this.areProfilesLoaded = false;
    this.profilesLoading = true;
    apiClient.tests.getPetProfiles()
      .then((profileResponse) => {
        if (profileResponse?.data && profileResponse?.data.profiles) {
          this.petProfiles = profileResponse.data.profiles;
        }
      })
      .finally(() => {
        this.areProfilesLoaded = true;
        this.profilesLoading = false;
      });
  };

  updatePetProfiles = async (testId: string, profile: Partial<Profile>) => {
    const profileResponse = await apiClient.tests.updatePetProfile(testId, profile);
    if (profileResponse?.data) {
      this.petProfile = { ...this.petProfile, ...profile } as Profile;
    }
  };

  deletePetProfile = async (testId: string) => {
    const profileResponse = await apiClient.tests.deletePetProfile(testId);
    if (profileResponse?.status === 204) {
      await this.getPetProfiles();
      return profileResponse;
    }
    throw new Error();
  };

  setProfile(profiles: Profile[]) {
    [this.petProfile] = profiles;
    this.setProfileLoaded(true);
  }

  unsetProfiles() {
    this.petProfile = null;
    this.petProfiles = [];
    this.isProfileLoaded = false;
    this.areProfilesLoaded = false;
  }

  setProfileLoaded(loaded: boolean) {
    this.isProfileLoaded = loaded;
  }

  getUserAddresses = async (testId: string) => {
    this.isUserAddressesLoaded = false;
    this.userAddressesLoading = true;
    const userAddressesResponse = await apiClient.tests.getUserAddresses(testId);
    if (userAddressesResponse?.data) {
      this.userAddresses = userAddressesResponse.data;
      this.isUserAddressesLoaded = true;
    }
    this.userAddressesLoading = false;
  };

  createAddress = async (address: AddressInfo) => {
    this.isUserAddressesLoaded = false;
    const createAddressResponse = await apiClient.tests.createAddress(address);
  };

  recollectAccept = async (addressId: string, testId: string) => {
    this.recollectAcceptLoading = true;
    await apiClient.tests.recollectAccept(addressId, testId).then((recollectAcceptResponse) => {
      if (recollectAcceptResponse?.data) {
        this.recollectAcceptResults = recollectAcceptResponse.data;
      }
    })
      .finally(() => {
        this.recollectAcceptLoaded = true;
        this.recollectAcceptLoading = false;
      });
  };

  getShareSheetText = async (id: string) => {
    this.shareSheetTextLoading = true;
    this.shareSheetTextLoaded = false;
    const response = await apiClient.tests.getShareSheetText(id);
    if (response?.data?.data?.values) {
      this.shareSheetText = response.data.data.values;
      this.shareSheetTextLoaded = true;
    }
    this.shareSheetTextLoading = false;
  };

  generateShareImage = async (testGuid: string, petPayload: BreedResult) => {
    this.generatedImageLoading = true;
    this.generatedImageLoaded = false;
    const response = await apiClient.tests.generateShareImage(testGuid, petPayload);
    if (response?.data) {
      this.generatedImageLoaded = true;
      this.generatedImage = response.data;
    }
    this.generatedImageLoading = false;
  };

  updateMessagesReceiveStatus = async (
    testId: string,
    profile: Profile,
  ) => {
    const response = await apiClient.tests.updateMessagesReceiveStatus(
      testId,
      profile,
    );
    if (response?.data) {
      this.petProfile = { ...this.petProfile, ...profile };
    }
  };
}

export default TestsStore;
