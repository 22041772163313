import { useEffect } from 'react';
import { useStores } from 'stores/useStores';

export const useGetKitPrices = () => {
  const { pagesStore } = useStores();

  useEffect(() => {
    if (!pagesStore.areKitPricesLoaded) {
      pagesStore.getKitPrices();
    }
  }, [pagesStore, pagesStore.areKitPricesLoaded]);

  return { pagesStore };
};
