import {
  configure,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import apiClient from 'apiClient/index';
import { TSupportedLocales } from 'petdna-lib-uicomponents/constants';
import { HealthTraitPrediction } from 'hooks/useHealthTraitRecords';
import {
  HealthTraitBodyFunction,
  HealthTraitInfo,
  HealthTraitInfoFilters,
  HealthTraitResultType,
  Trait,
} from './types/Traits';
import { IDashboardHealthReport } from './types/Health';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

type HealthTraitData = { traits?: Trait[], entitled: boolean };

class HealthReportStore {
  healthReportLoading = true;
  healthTraitsData = new Map<string, HealthTraitData>();
  eligible = false;
  dashboardHealthReport: IDashboardHealthReport | null = null;
  dashboardHealthReportLoading = true;
  healthTraitInfo = new Map<string, HealthTraitInfo>();
  bodyFunctions: HealthTraitBodyFunction[] | null = null;
  healthTraitResultTypes: HealthTraitResultType[] | null = null;
  healthTraitInfoLoading = true;
  healthTraitInfoList: HealthTraitInfo[] | null = null;

  complexHealthTraits: Set<string> | null = null;

  constructor() {
    makeAutoObservable(this, {
      healthTraitInfo: observable.deep,
      healthTraitsData: observable.deep,
    });
  }

  async getComplexHealthTraits() {
    if (this.complexHealthTraits) {
      return;
    }

    const response = await apiClient.healthTraits.getComplexHealthTraits();
    runInAction(() => {
      this.complexHealthTraits = new Set(
        response.data?.data.healthTraits.map(({ traitId }) => traitId) ?? [],
      );
    });
  }

  getHealthReport = async (testId: string) => {
    if (this.healthTraitsData.has(testId)) {
      return;
    }

    this.healthReportLoading = true;
    const response = await apiClient.healthTraits.getHealthReport(testId);
    await runInAction(async () => {
      if (response.data) {
        this.healthTraitsData.set(testId, {
          ...response.data,
          traits: await this.updatePredictionsForComplexTraits(response.data.traits),
        });
      }
      this.healthReportLoading = false;
    });
  };

  async updatePredictionsForComplexTraits(traits?: Trait[]): Promise<Trait[] | undefined> {
    if (!traits) {
      return traits;
    }

    await this.getComplexHealthTraits();

    return traits.map((trait) => {
      if (!this.complexHealthTraits?.has(trait.id.toLowerCase())) {
        return {
          id: trait.id.toLowerCase(),
          prediction: trait.prediction,
        };
      }

      // All complex health traits should have only GREEN risk level
      return {
        id: trait.id.toLowerCase(),
        prediction: HealthTraitPrediction.GREEN,
      };
    });
  }

  getHealthDashboardReport = async (testId: string, locales: TSupportedLocales[]) => {
    await this.getHealthReport(testId);

    runInAction(async () => {
      const data = this.healthTraitsData.get(testId);

      if (!data || !data.entitled) {
        this.dashboardHealthReportLoading = false;
        return;
      }

      this.dashboardHealthReportLoading = true;
      const {
        data: { data: { dashboardHealthReport } },
      } = await apiClient.healthTraits.getDashboardHealthReport(locales);

      if (dashboardHealthReport) {
        this.dashboardHealthReport = dashboardHealthReport;
      }

      if (!data.traits?.length) {
        const queryParams = dashboardHealthReport?.healthReportPrice?.queryParams;
        const offerId = new URLSearchParams(queryParams).get('offers') ?? '';

        await this.getEligibility(testId, offerId);
      }

      this.dashboardHealthReportLoading = false;
    });
  };

  getEligibility = async (testId: string, offerId: string) => {
    if (!testId || !offerId) {
      this.eligible = false;
      return;
    }

    const { data: { eligible } } = await apiClient.healthTraits.getEligibility(testId, offerId);

    runInAction(() => {
      this.eligible = Boolean(eligible);
    });
  };

  async getHealthTraitInfo(traitId: string) {
    if (this.healthTraitInfo.has(traitId)) {
      return this.healthTraitInfo.get(traitId);
    }

    const traitResponse = await apiClient.healthTraits.getHealthTraitInfo(traitId);
    if (traitResponse?.data.data.healthTraits?.length) {
      const data = traitResponse.data.data.healthTraits[0];

      runInAction(() => {
        this.healthTraitInfo.set(traitId, data);
      });

      return data;
    }

    return undefined;
  }

  async getHealthTraitResultType() {
    if (this.healthTraitResultTypes) {
      return;
    }

    const response = await apiClient.healthTraits.getHealthTraitResultTypes();
    if (response?.data.data.healthTraitResultTypes?.length) {
      runInAction(() => {
        this.healthTraitResultTypes = response.data.data.healthTraitResultTypes;
      });
    }
  }

  async getBodyFunctions() {
    if (this.bodyFunctions) {
      return;
    }

    const response = await apiClient.healthTraits.getBodyFunctions();
    if (response?.data.data.healthTraitBodyFunctions?.length) {
      runInAction(() => {
        this.bodyFunctions = response.data.data.healthTraitBodyFunctions;
      });
    }
  }

  async listHealthTraitsInfo(filters: HealthTraitInfoFilters) {
    this.healthTraitInfoLoading = true;

    const response = await apiClient.healthTraits.listHealthTraitInfo(filters);

    runInAction(() => {
      this.healthTraitInfoList = response.data.data.healthTraits;
      this.healthTraitInfoLoading = false;
    });
  }
}

export default HealthReportStore;
