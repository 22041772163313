import { HYGRAPH_FOOTER_ID } from 'constants/hygraphShemeId';

export const GET_FOOTER_QUERY = `query Footer($id: ID = "${HYGRAPH_FOOTER_ID}") {
  footer(where: { id: $id }) {
    name
    leftMenuItems {
      name
      link
      label
      cssClass
      externalLink
    }
    rightMenuItems {
      name
      link
      label
      cssClass
      externalLink
    }
  }
}
`;
