import {
  action, configure, makeAutoObservable, observable,
} from 'mobx';
import {
  AllMatchesResponse, AllPawfectMatchesResponse,
} from 'stores/types/Matches';
import apiClient from 'apiClient/index';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class ChatMatchesStore {
  pawfectMatchesData: AllPawfectMatchesResponse | null = null;
  dnaMatchesData: AllMatchesResponse | null = null;
  loadingPawfectMatches: boolean = false;
  loadingDnaMatches: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      pawfectMatchesData: observable,
      dnaMatchesData: observable,
      loadingPawfectMatches: observable,
      loadingDnaMatches: observable,
      getAllMatches: action,
      getAllPawfectMatches: action,
    });
  }

  getAllMatches = async (
    page?: number,
    pageSize?: number,
  ) => {
    this.loadingPawfectMatches = true;
    const matchesResponse = await apiClient.matches
      .getAllMatches(page, pageSize);

    this.dnaMatchesData = matchesResponse.data;

    this.loadingPawfectMatches = false;
  };

  getAllPawfectMatches = async (
    page?: number,
    pageSize?: number,
  ) => {
    this.loadingPawfectMatches = true;
    const matchesResponse = await apiClient.matches
      .getAllPawfectMatches(page, pageSize);

    this.pawfectMatchesData = matchesResponse.data;

    this.loadingPawfectMatches = false;
  };
}

export default ChatMatchesStore;
