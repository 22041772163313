import { useStores } from 'stores/useStores';
import { useEffect } from 'react';

export const useCheckSession = () => {
  const { sessionStore } = useStores();

  useEffect(() => {
    if (!sessionStore.sessionChecked) sessionStore.checkSession();
  }, [sessionStore, sessionStore.sessionChecked]);

  return { sessionStore };
};
