export const GET_POLICY_QUERY = `
    query Policy($policyId: String) {
      policy(where: {policyId: $policyId}) {
        id
        name
        policyId
        text {
          html
        }
      }
    }
`;
