import { useEffect } from 'react';
import { useStores } from 'stores/useStores';

export const useGetUserProfile = () => {
  const { sessionStore, userStore } = useStores();

  useEffect(() => {
    if (sessionStore.sessionValid && !userStore.isUserProfileLoaded) userStore.getUserProfile();
  }, [sessionStore, sessionStore.sessionValid, userStore.userProfile, userStore]);

  return { userStore };
};
