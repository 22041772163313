import { UserRepository } from '@amityco/ts-sdk';
import { FC, useEffect, useState } from 'react';
import { useGetUserProfile } from 'hooks/useGetUserProfile';
import { useStores } from 'stores/useStores';

export const useGetAmityUser = () => {
  const { userStore } = useGetUserProfile();
  const { userProfile } = userStore;
  const { amityStore } = useStores();
  const [userData, setUser] = useState<Amity.LiveObject<Amity.User>>();
  const { data: user, loading, error } = userData ?? {};

  useEffect(() => {
    /*
     * If you only need the user object and do not require any assosiated events you
     * can unsubscribe immediately
     */
    if (userProfile?.UserId && amityStore.client && amityStore.isConnected) {
      amityStore.getAmityUser(userProfile?.UserId);
    }
  }, [
    userProfile,
    amityStore.client,
    amityStore.isConnected,
  ]);

  return { amityStore };
};
