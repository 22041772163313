import { NavLink, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useGetUserProfile } from 'hooks/useGetUserProfile';
import DisplayNameModal from 'components/Modals/DisplayNameModal/DisplayNameModal';
import { useGetPetProfile } from 'hooks/useGetPetProfile';
import { useTranslation } from 'react-i18next';
import petIcon from '../../assets/svgs/pets-icon.svg';
import chatIcon from '../../assets/svgs/chat-icon.svg';
import partnershipIcon from '../../assets/svgs/partnership-icon.svg';
import './DashboardNavigation.scss';

interface IOption {
  title: string,
  route: string,
  action?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  icon: string,
  shouldShow?: boolean,
  altText: string,
  end: boolean,
}

const DashboardNavigation = ({ unreadCount }: { unreadCount: number }) => {
  const { t } = useTranslation();
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const { userStore: { userProfile } } = useGetUserProfile();
  const { testsStore: { petProfile } } = useGetPetProfile();
  const params = useParams();
  const testId = params.testId ?? petProfile?.testGUID;

  const onCloseNameModal = () => {
    setIsNameModalOpen(false);
  };

  const handleChatClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (userProfile?.PetDNADisplayName === null || userProfile?.PetDNADisplayName === undefined) {
      event.preventDefault();
      setIsNameModalOpen(true);
    }
  };

  const menuOptions: IOption[] = [
    {
      title: t('my.pets'),
      route: `/${testId}`,
      icon: petIcon,
      altText: '',
      end: true,
    },
    {
      title: t('navbar.messages'),
      route: '/all/chat',
      action: handleChatClick,
      icon: chatIcon,
      altText: '',
      shouldShow: (!!testId),
      end: false,
    },
    {
      title: t('navbar.partnership'),
      route: '/partnerships',
      icon: partnershipIcon,
      altText: '',
      end: true,
    },
  ];

  return (
    <>
      <ul className="d-flex dashboard-nav">
        {menuOptions.filter((option) => option.shouldShow !== false).map(({
          route, title, end, icon, altText, action,
        }) => (
          <li className="d-flex" key={title}>
            <NavLink onClick={action} to={route} className="bg-transparent border-0 d-flex option float-left align-middle text-center" end={end}>
              <img src={icon} id="security-tools-icon" alt={altText} />
              <p className="option-text">{title}</p>
              {title === 'Messages' && unreadCount
                ? (
                  <span className="badge unread-count-badge">
                    {unreadCount < 100 ? unreadCount : '99+'}
                  </span>
                ) : null}
            </NavLink>
          </li>
        ))}
      </ul>
      {
        testId
        && (
          <DisplayNameModal
            isNameModalOpen={isNameModalOpen}
            id={testId}
            onClose={() => onCloseNameModal()}
          />
        )
      }
    </>
  );
};

export default DashboardNavigation;
