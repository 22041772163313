import { Trait } from 'stores/types/Traits';
import { useEffect, useMemo } from 'react';
import { useStores } from 'stores/useStores';

export interface UseHealthTraitsPredictionsOptions {
  testId?: string;
}

const useHealthTraitsPredictions = ({
  testId,
}: UseHealthTraitsPredictionsOptions): {
  traits: Trait[] | null;
  loading: boolean;
  entitled: boolean;
} => {
  const { healthReportStore } = useStores();
  const { healthTraitsData, healthReportLoading } = healthReportStore;

  useEffect(() => {
    if (!testId) {
      return;
    }

    healthReportStore.getHealthReport(testId);
  }, [testId, healthReportStore]);

  return useMemo(() => {
    if (!testId) {
      return {
        loading: healthReportLoading,
        traits: null,
        entitled: false,
      };
    }

    const data = healthTraitsData.get(testId);
    return {
      loading: healthReportLoading,
      traits: data?.traits ?? null,
      entitled: data?.entitled ?? false,
    };
  }, [healthReportLoading, testId]);
};

export default useHealthTraitsPredictions;
