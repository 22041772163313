import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import { useGetUserPetProfiles } from 'hooks/useGetUserPetProfiles';

import './card.scss';
import './cardContain.scss';
import './Pill.scss';

type SocialSharingCarouselProps = {
  imgUrl?: string
};

const SocialSharingCarousel: React.FC<SocialSharingCarouselProps> = ({ imgUrl }) => {
  useGetUserPetProfiles();

  const renderImageItem = () => (
    imgUrl ? (
      <div className="d-flex justify-content-center">
        <img
          src={imgUrl}
          alt="Chart"
          className="img-fluid social-image-share"
        />
      </div>
    ) : (
      <div className="container chat_container mt-64">
        <div className="row gy-16 h-100">
          <div className="d-flex col-9 gy-16">
            <span className="spinner-border spinner-border-sm me-4" aria-hidden="true" />
            <span role="status">Generating image...</span>
          </div>
        </div>
      </div>
    )

  );

  const imagesUrl = [
    renderImageItem(),
  ];

  return (

    <div className="col-lg-6 col-md-6 col-sm-12 border-end">
      <Swiper
        className="mySwiper"
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
      >
        {
          imagesUrl.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={idx}>
              {item}
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default SocialSharingCarousel;
