import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useStores } from 'stores/useStores';

export const useGetBreeds = () => {
  const { breedStore, sessionStore } = useStores();
  const { testId } = useParams();

  useEffect(() => {
    if (testId && sessionStore.sessionValid) {
      breedStore.getBreedResults(testId);
    }
  }, [breedStore, sessionStore.sessionValid, testId]);

  return { breedStore };
};
