import {
  HealthTraitListFilters,
  HealthTraitListFiltersAllValues,
} from 'routes/HealthReport/components/Filters/Filters';
import { HealthTraitRecord, Trait } from 'stores/types/Traits';
import { useEffect, useMemo } from 'react';
import { useStores } from 'stores/useStores';
import useHealthFeature from 'hooks/useHealthFeature';

export enum HealthTraitPrediction {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export const TRAIT_RESULT_TYPE_ORDER: any = {
  [HealthTraitPrediction.GREEN]: 1,
  [HealthTraitPrediction.YELLOW]: 2,
  [HealthTraitPrediction.RED]: 3,
};

const compareHealthTraitRecords = (l: HealthTraitRecord, r: HealthTraitRecord) => {
  if (r.type.traitPredictionId !== l.type.traitPredictionId) {
    return TRAIT_RESULT_TYPE_ORDER[r.type.traitPredictionId]
      - TRAIT_RESULT_TYPE_ORDER[l.type.traitPredictionId];
  }

  if (r.type.traitPredictionId === HealthTraitPrediction.GREEN
    && r.info.isComplex !== l.info.isComplex) {
    return r.info.isComplex ? 1 : -1;
  }

  if (l.info.title > r.info.title) {
    return -1;
  }

  if (l.info.title < r.info.title) {
    return 1;
  }

  return 0;
};

export interface UseHealthTraitRecordsOptions {
  testId?: string;
  filters: HealthTraitListFilters;
  allFilterValues: HealthTraitListFiltersAllValues | null;
  page: number;
  perPage: number;
}

export interface HealthTraitRecordsResponse {
  records: HealthTraitRecord[];
  total: number;
  page: number;
  loading: boolean;
  predictions: Trait[] | null;
}

const useHealthTraitRecords = ({
  filters,
  allFilterValues,
  page,
  perPage,
}: UseHealthTraitRecordsOptions): HealthTraitRecordsResponse => {
  const { healthReportStore } = useStores();
  const { healthTraitInfoLoading, healthTraitInfoList } = healthReportStore;

  const {
    predictions,
    loading: healthPredictionsLoading,
  } = useHealthFeature();
  const predictionsById = useMemo(() => predictions?.reduce((acc, p) => {
    acc[p.id.toLowerCase()] = p;
    return acc;
  }, {} as Record<string, Trait>), [predictions]);

  useEffect(() => {
    if (!allFilterValues || !predictionsById) {
      return;
    }

    healthReportStore.listHealthTraitsInfo({
      traitIds: filters.statuses.length !== allFilterValues.statuses.length
        ? Object.keys(predictionsById)
          .filter(
            (id) => filters
              .statuses
              .find((status) => predictionsById[id].prediction.endsWith(status.traitPredictionId)),
          )
        : undefined,
      breedIds: filters.breeds.length !== allFilterValues.breeds.length
        ? filters.breeds.map(({ id }) => id)
        : undefined,
      bodyFunctionIds: filters.bodyFunctions.length !== allFilterValues.bodyFunctions.length
        ? filters.bodyFunctions?.map(({ id }) => id)
        : undefined,
      query: filters.search,
    });
  }, [allFilterValues, filters, healthReportStore, predictionsById]);

  const allRecords = useMemo(() => (
    predictionsById && healthTraitInfoList && allFilterValues
      ? healthTraitInfoList
        .filter((rec) => !!predictionsById[rec.traitId])
        .map((rec) => ({
          info: rec,
          prediction: predictionsById[rec.traitId],
          type: allFilterValues
            .statuses
            .find(
              (status) => predictionsById[rec.traitId]
                .prediction
                .includes(status.traitPredictionId),
            )!,
        }))
        .sort(compareHealthTraitRecords)
      : []
  ), [allFilterValues, healthTraitInfoList, predictionsById]);

  return useMemo(() => ({
    predictions,
    records: allRecords.slice((page - 1) * perPage, page * perPage),
    page,
    total: allRecords?.length ?? 0,
    loading: healthTraitInfoLoading || healthPredictionsLoading,
  }), [
    predictions,
    allRecords,
    page,
    perPage,
    healthTraitInfoLoading,
    healthPredictionsLoading,
  ]);
};

export default useHealthTraitRecords;
