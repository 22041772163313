import { useEffect } from 'react';
import { useStores } from 'stores/useStores';
import { useParams } from 'react-router-dom';

export const useGetPetProfile = () => {
  const { testsStore, sessionStore } = useStores();
  const { petProfiles } = testsStore;
  const params = useParams();
  const testId = params.testId ?? petProfiles?.[0]?.testGUID;

  useEffect(() => {
    if (testId && sessionStore.sessionValid) {
      testsStore.getPetProfile(testId);
    }
  }, [sessionStore.sessionValid, testId, testsStore]);

  return { testsStore };
};
