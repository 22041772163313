import { TSupportedCountryCodes } from 'constants/LCID';
import { getCountryCodeCookieFromVars } from './useCountryCode';

export const DEFAULT_COUNTRY_CODE = 'US';

export const useGetCountryCode = () => {
  const CountryCodeKey: TSupportedCountryCodes = getCountryCodeCookieFromVars();

  return CountryCodeKey || DEFAULT_COUNTRY_CODE;
};
