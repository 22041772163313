import { useStores } from 'stores/useStores';
import { useEffect } from 'react';

export const useLoadFooter = () => {
  const { pagesStore } = useStores();

  useEffect(() => {
    pagesStore.getFooter();
  }, []);

  return { footerData: pagesStore?.footerData };
};
