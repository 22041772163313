import {
  HEALTH_TRAIT_RESULT_TYPE_FIELDS_QUERY,
} from 'constants/query/healthTraitResultTypeQuery';

export const getSectionHealthTraitResultFieldsQuery = () => `
  title {
    html
  }
  healthTraitResultType {
    ${HEALTH_TRAIT_RESULT_TYPE_FIELDS_QUERY}
  }
  backgroundColor {
    hex
  }
  textColor {
    hex
  }
  genesBackgroundColor {
    hex
  }
  genesTextColor {
    hex
  }
  borderColor {
    hex
  }
  borderWidth
`;
