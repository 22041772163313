import { SECTION_FEATURE_QUERY } from './sections/sectionFeatureQuery';
import { SECTION_SUBMIT_FOR_CO_BRAND } from './sections/sectionSubmitForCoBrandQuery';
import { SECTION_TEXT_AND_IMAGE_QUERY } from './sections/sectionTextAndImageQuery';
import { SECTION_WHY_ANCESTRY_QUERY } from './sections/sectionWhyAncestryQuery';

export const PAGE_CO_BRANDED_QUERY = `
query PageCoBranded($name: String!, $locale: [Locale!]!) {
  pageCoBranded(where: { name: $name }, locales: $locale) {
    name
    sections {
      ... on ${SECTION_SUBMIT_FOR_CO_BRAND}
      ... on ${SECTION_FEATURE_QUERY}
      ... on ${SECTION_TEXT_AND_IMAGE_QUERY}
      ... on ${SECTION_WHY_ANCESTRY_QUERY}
    }
  }
}
`;
