import { Profile } from 'stores/types/Profiles';
import { getDomain } from 'helpers/getDomain';

export function getImageURL(test: Profile, isAvatar = false) {
  const domain = getDomain();
  return test.imageId ? `https://mediasvc.${domain}/v2/image/namespaces/62827/media/${test.imageId}?client=petdna-profile${isAvatar ? '&maxWidth=200&maxHeight=200' : ''}` : '';
}

export function getSharedImageURL(imageId: string, isAvatar = false) {
  const domain = getDomain();
  return imageId ? `https://mediasvc.${domain}/v2/image/namespaces/62827/media/${imageId}?client=petdna-share${isAvatar ? '&maxWidth=200&maxHeight=200' : ''}` : '';
}
