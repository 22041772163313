import { getTransformationImageObj } from '../../../helpers/hygraphAssetTransformation';
import { getImageWithCaptionQuery } from '../commonQuery';

export const SECTION_FEATURE_QUERY = `
    SectionFeature {
      id
      __typename
      backgroundColor {
        hex
      }
      ${getImageWithCaptionQuery(getTransformationImageObj(296, 360), 'imageWithCaptions')}
      name
      sectionType
      featuresOffset
    }`;
