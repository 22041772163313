export const GET_QUESTIONS_QUERY = `
  questions {
    name
    type
    label {
      html
      text
    }
    locale
    required
    placeholder {
      html
      text
    }
    errorMessage
    options {
      label {
        html
        text
      }
      value
      locale
    }
  }
`;
