import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { SocialShareModalContext } from 'contexts/SocialShareModalContext';
import toast from 'react-hot-toast';
import downloadIcon from 'assets/svgs/download.svg';
import linkIcon from 'assets/svgs/link.svg';
import mailSent from 'assets/svgs/mail-sent.svg';

import './SocialSharingModal.scss';
import TextBox from 'components/Modals/SocialSharingModal/TextBox';
import SocialSharingButtonsList from 'components/Button/SocialShareButtonsList/SocialShareButtonsList';
import { useGetUserPetProfiles } from 'hooks/useGetUserPetProfiles';
import { MIXED_BREED_SHARE_SHEET_TEXT_ID, PURE_BREED_SHARE_SHEET_TEXT_ID } from 'constants/query/shareSheetTextQuery';
import { TText } from 'stores/types/Pages';
import SocialSharingCarousel from 'components/Modals/SocialSharingModal/SocialSharingCarousel';
import { useLocation } from 'react-router-dom';
import { useGetBreeds } from 'hooks/useGetBreeds';
import { getSharedImageURL } from 'helpers/getImageURL';
import { observer } from 'mobx-react-lite';
import { getDomain } from 'helpers/getDomain';
import { BreedResult } from 'stores/types/Breeds';
import NotFound from 'components/NotFound/NotFound';
import WebShare from '../WebShare/WebShare';

const IconButton: React.FC<{
  iconSrc: string;
  text: string;
  action?: any;
  children?: React.ReactNode
}> = (
  {
    iconSrc, text, action, children,
  },
) => (
  <div className="icon-button-wrapper">
    <button
      type="button"
      className="btn btn-info round-6 btn-rounded-icon"
      onClick={action}
    >
      <img height="20" width="20" src={iconSrc} alt={text} />
    </button>
    <p className="ms-1 icon-button-text">{text}</p>
    {children}
  </div>
);

const SocialSharingModal: React.FC = observer(() => {
  const context = useContext(SocialShareModalContext);
  const modalRef = useRef<HTMLDivElement>(null);
  const { testsStore } = useGetUserPetProfiles();
  const { getShareSheetText } = testsStore;

  const { breedStore } = useGetBreeds();
  const { breedResults } = breedStore;

  const { generateShareImage, generatedImage } = testsStore;

  const [modalTitle, setModalTitle] = useState<string | undefined>(undefined);
  const [textBoxContent, setTextBoxContent] = useState<TText>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [errorImage, setErrorImage] = useState<boolean>(false);

  if (!context) {
    throw new Error('SocialShareModal must be used within a SocialShareModalProvider!.');
  }
  const { isOpen, closeModal } = context;

  const { petProfile } = testsStore;

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const testId = pathSegments[1];
  const domain = getDomain();
  const shareUrl = `https://petdna.${domain}/share/${testId}`;

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        if (isOpen && breedResults !== null) {
          await generateShareImage(testId, breedResults as unknown as BreedResult);
          document.body.classList.add('body-no-scroll');
        } else {
          document.body.classList.remove('body-no-scroll');
        }
      } catch (error) {
        console.error('Error generating share image: ', error);
        setErrorImage(true);
      }
    };

    fetchImageData();
  }, [isOpen, breedResults, generateShareImage, testId]);

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (modalRef.current === e.target) {
      closeModal();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(
    () => {
      if (generatedImage?.message?.external_media_id) {
        const textId = generatedImage?.message?.breeds.length === 1
          ? PURE_BREED_SHARE_SHEET_TEXT_ID
          : MIXED_BREED_SHARE_SHEET_TEXT_ID;
        getShareSheetText(textId)
          .then(() => {
            const values = testsStore.shareSheetText;
            setModalTitle(values?.title);
            setTextBoxContent(values?.text);
            setImageUrl(getSharedImageURL(generatedImage?.message?.external_media_id));
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    },
    [
      generatedImage?.message?.breeds.length,
      generatedImage?.message?.external_media_id,
      getShareSheetText,
      testsStore.shareSheetText,
    ],
  );

  if (!isOpen) return null;

  const handleDownload = () => {
    if (imageUrl) {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `PET-DNA-${generatedImage?.message?.pet_name}.png`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => console.error('Download failed:', error));
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        toast.success('Image URL copied to clipboard!');
      })
      .catch((err) => {
        toast.error('Failed to copy Image URL to clipboard!');
      });
  };

  return (
    <div
      className="modal-social-share show d-block"
      tabIndex={-1}
      onClick={handleClickOutside}
      onKeyDown={handleKeyDown}
      ref={modalRef}
      role="presentation"
    >
      {imageUrl ? (
        <>
          <div className="modal-social-share-dialog custom-modal-style ">
            <div className="modal-social-share-content ">
              <div className="modal-social-share-header p-0">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <button type="button" className="btn-close" onClick={closeModal}>X</button>
                </div>
              </div>
              <div className="modal-social-share-body">
                <div className="container-fluid">
                  <div className="row" style={{ marginTop: '-20px' }}>
                    <SocialSharingCarousel
                      imgUrl={getSharedImageURL(generatedImage?.message?.external_media_id)}
                    />
                    <div className="col-lg-6 col-md-6  col-sm-12 ">
                      <div className="row pl-4">
                        <div className="col-12">
                          <h3 className="h1 d-none d-md-block ">{modalTitle}</h3>
                        </div>
                        <div className="col-lg-12 d-none d-md-block ">
                          <IconButton iconSrc={downloadIcon} text="Save image to device" action={handleDownload} />
                          <IconButton iconSrc={linkIcon} text="Copy Link" action={handleCopyLink} />
                          <IconButton iconSrc={mailSent} text="Share To...">
                            <SocialSharingButtonsList shareUrl={shareUrl} />
                          </IconButton>
                        </div>
                        <div className="col-12 mt-12 ">
                          <TextBox
                            content={textBoxContent?.text}
                            breedResults={breedResults}
                            petName={petProfile?.name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-12 d-sm-block d-md-none social_share_modal_btn_container">
            <div className="row">
              <div className="col d-flex justify-content-center align-items-center flex-column">
                <button
                  type="button"
                  onClick={handleDownload}
                  className="btn btn-info round-6 btn-rounded-icon"
                >
                  <img height="20" width="20" src={downloadIcon} alt="downloadIcon" />
                </button>
                <p className="ms-1">Download</p>
              </div>
              <div className="col d-flex justify-content-center align-items-center flex-column">
                <WebShare url={shareUrl} />
              </div>
              <div className="col d-flex justify-content-center align-items-center flex-column">
                <button
                  type="button"
                  onClick={handleCopyLink}
                  className="btn btn-info round-6 btn-rounded-icon"
                >
                  <img height="20" width="20" src={mailSent} alt="linkIcon" />
                </button>
                <p className="ms-1">Copy Link</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="modal-social-share-dialog custom-modal-style ">
          <div className="modal-social-share-content">
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '400px',
            }}
            >
              {
                !errorImage ? (
                  <>
                    <span className="spinner-border spinner-border-lg  " aria-hidden="true" />
                    <span className="mt-12" role="status">Generating image....</span>
                  </>
                ) : (
                  <NotFound />
                )
              }

            </div>
          </div>
        </div>
      )}

    </div>
  );
});

export default SocialSharingModal;
