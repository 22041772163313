import React from 'react';
import {
  Navigate,
  Outlet, useLocation, useNavigate,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStores } from 'stores/useStores';

const ProtectedRoute = observer(() => {
  const location = useLocation();
  const { sessionStore } = useStores();
  const { sessionChecked, sessionValid } = sessionStore;

  if (!sessionChecked) {
    return null;
  }

  if (!sessionValid) {
    if (!location.pathname.startsWith('/offer')) {
      return (
        <Navigate
          to={`/sign-in?returnUrl=${encodeURIComponent(location.pathname + location.search)}`}
          replace
        />
      );
    }
  }

  return <Outlet />;
});

export default ProtectedRoute;
