import {
  getSectionHealthTraitResultFieldsQuery,
} from 'constants/query/sections/sectionHealthTraitResultFieldsQuery';
import {
  getSectionTraitDescriptionQuery,
} from 'constants/query/sections/sectionTraitDescriptionQuery';
import { SECTION_BULLET_LIST_QUERY } from 'constants/query/sections/sectionBulletListQuery';
import { SECTION_OPEN_URL_QUERY } from 'constants/query/sections/sectionOpenUrlQuery';
import { getSectionTextIconQuery } from 'constants/query/sections/sectionTextIconQuery';
import { getImageWithAltQuery } from 'constants/query/commonQuery';
import { HEALTH_TRAIT_BODY_FUNCTION_FIELDS } from 'constants/query/healthTraitBodyFunctionsQuery';
import { HealthTraitInfoFilters } from 'stores/types/Traits';
import { Pagination } from 'constants/types/pagination';
import { SECTION_TEXT_AND_TITLE_QUERY } from './sections/sectionTextAndTitleQuery';

export const getHealthTraitInfoListQueryForFilters = (
  filters: HealthTraitInfoFilters,
  pagination: Pagination,
) => {
  const args = [
    filters.traitIds
      ? { variable: '$traitIds: [String]', filter: 'traitId_in: $traitIds' }
      : null,
    filters.breedIds
      ? { variable: '$breedIds: [ID]', filter: 'affectedBreeds_some: { id_in: $breedIds }' }
      : null,
    filters.bodyFunctionIds
      ? { variable: '$bodyFunctionIds: [ID]', filter: 'bodyFunction: { id_in: $bodyFunctionIds }' }
      : null,
    filters.query
      ? { variable: '$query: String', filter: 'title_contains: $query' }
      : null,
  ].filter(Boolean) as { variable: string, filter: string }[];
  const params = args.length
    ? `(where: {${args.map((arg) => arg.filter).join(', ')}}, skip: ${pagination.skip}, first: ${pagination.first})`
    : `(skip: ${pagination.skip}, first: ${pagination.first})`;

  return `
    query HealthTraitsQuery${args.length ? `(${args.map((arg) => arg.variable).join(', ')})` : ''} {
      healthTraits${params} {
        traitId
        isComplex
        title
        description
        bodyFunction {
          ${HEALTH_TRAIT_BODY_FUNCTION_FIELDS}
        }
      }
    }
  `;
};

export const getComplexHealthTraitsQuery = (pagination: Pagination) => `
  query HealthTraitsQuery {
    healthTraits(skip: ${pagination.skip}, first: ${pagination.first}, where: { isComplex: true }) {
      traitId
    }
  }
`;

export const HEALTH_TRAIT_INFO_QUERY = `
  query HealthTraitsQuery($traitId: String!) {
    healthTraits(where: { traitId_in: [$traitId] }) {
      traitId
      title
      description
      isComplex
      bodyFunction {
        ${HEALTH_TRAIT_BODY_FUNCTION_FIELDS}
      }
      genesTitle {
        html
      }
      genes
      affectedBreeds {
        name
      }
      partnership {
        name
        ${getImageWithAltQuery({}, 'partnerLogo')}
      }
      sectionsHealthTraitResults {
        ${getSectionHealthTraitResultFieldsQuery()}
      }
      sections {
        __typename
        ... on ${getSectionTraitDescriptionQuery(400, 400)}
        ... on ${SECTION_TEXT_AND_TITLE_QUERY}
        ... on ${SECTION_BULLET_LIST_QUERY}
        ... on ${SECTION_OPEN_URL_QUERY}
        ... on ${getSectionTextIconQuery()}
      }
    }
  }
`;
