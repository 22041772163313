import React, { useRef } from 'react';
import copyIcon from 'assets/svgs/copy-icon.svg';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface TexBoxProps {
  breedResults: any
  content?: string
  petName?: string
}

const TextBox: React.FC<TexBoxProps> = ({ content, breedResults, petName }) => {
  const { t } = useTranslation();
  const textBoxRef = useRef<HTMLDivElement>(null);

  const handleCopyClick = () => {
    const textToCopy = textBoxRef.current?.textContent || '';
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        toast.success('Text copied to clipboard');
      })
      .catch((err) => {
        toast.error(`Failed to copy text ${err}`);
      });
  };

  const breedsString = breedResults?.breeds?.map((breed: {
    percentage: any;
    name: any;
  }) => `${breed.percentage}% ${breed.name}`).join(', ');
  const petDetails = {
    PET_NAME: petName,
    BREEDS: breedsString,
  };

  return (
    <div className="text-box-container">
      <div className="text-outer-box">
        <div className="text-inner-box">
          <p>Copy Text</p>
          <button type="button" onClick={handleCopyClick} aria-label="Copy Text">
            <img
              height="25"
              width="25"
              src={copyIcon}
              alt="copyIcon"
              title="Copy Text"
            />
          </button>
        </div>
        <div ref={textBoxRef} className="text-box-wrapper">
          {t(content || '', petDetails)}
        </div>
      </div>
    </div>
  );
};

export default TextBox;
